import { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { LiveContext } from "../../Context/context.ts";
import releaseDates from "../../utils/releaseDates";

const PublicationsDatesTableTemplate = ({
  collection,
  library,
}): JSX.Element => {
  const { api_country_code } = useContext(LiveContext) as LiveContextType & {
    api_country_code: string;
  };
  const datesTable = collection.map(
    (
      elm: {
        issueNumber: number;
        date: string;
        date2: string;
        boxNumber: number;
      },
      index: React.Key
    ) => (
      <tr key={index}>
        <td>{elm.issueNumber}</td>
        <td>
          {elm?.date ||
            releaseDates({
              number: elm.issueNumber,
              langCode: api_country_code,
              startDate:
                api_country_code !== "HU"
                  ? new Date(2024, 11, 17)
                  : new Date(2025, 1, 3),
            })}
        </td>
        {!!library[7] && (
          <td>
            {elm?.date2 ||
              releaseDates({
                number: elm.issueNumber,
                langCode: api_country_code,
                startDate:
                  api_country_code !== "HU"
                    ? new Date(2025, 0, 30)
                    : new Date(2025, 2, 20),
              })}
          </td>
        )}
        {/* <td>{elm.boxNumber}</td> */}
      </tr>
    )
  );

  return (
    <div id="publications-dates-table-template">
      <div className="modal-body-inner">
        <Row>
          <Col xs={12}>
            <Table striped bordered>
              <thead style={{ lineHeight: "1.2em" }}>
                <tr>
                  <th style={{ verticalAlign: "middle" }}>{library[0]}</th>
                  <th style={{ verticalAlign: "middle" }}>{library[6]}</th>

                  {!!library[7] && (
                    <th>
                      {library[6]} <span className="h5 text-info">*</span>
                      <br />
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          lineHeight: "1em",
                        }}
                      >
                        {`(${library[7]})`}
                      </span>
                    </th>
                  )}

                  {/* <th>{library[3]}</th> */}
                </tr>
              </thead>
              <tbody>{datesTable}</tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PublicationsDatesTableTemplate;
