const langMap = {
    PL: 'pl-PL',
    HU: 'hu-HU',
    BG: 'bg-BG',
};

/**
 * @param {Object} params - Parameters object
 * @param {number} params.number - Number of the issue
 * @param {string} params.langCode - Language code
 * @param {number} [params.startIssue=86] - First issue for the period
 * @param {Date} [params.startDate=new Date(2024, 0, 9)] - Start date of the period
 * @returns {string|null} - Formatted date or `null` if inputs are invalid
 */

export default function releaseDates(
    {
        number, // issue number
        langCode, // language code
        //startIssue = 86, // first issue for the period
        startDate = new Date(2024, 0, 9) // January 9, 2024
    }) {

    if (typeof number === 'number' && langCode in langMap) {

        const startIssue = langCode ==='HU'? 77 : 86
        // step 3 weeks
        const step = 3 * 7 * 24 * 60 * 60 * 1000;
        const endIssue = 140; //Total number of issues

        const formatDate = (date) => {
            let formattedDate = date.toLocaleDateString(langMap[langCode], {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });

            if (langCode !== 'HU') {
                //pattern for polish and bulgaran dates
                formattedDate = formattedDate
                    .replace(/(\d+)\s(\w+)/, '$1\u00A0$2') //backspace between day and month
                    .replace(/(\d{4})$/, '$1\u00A0r.');   //backspace between year and 'r.'
            }

            else if (langCode === 'HU') {
                // pattern for hungarian date
                formattedDate = formattedDate
                    .replace(/(\d{4})\s(\w+)\s(\d+)/, '$1. $2\u00A0$3')
                    .replace(/\.$/, '');
            }

            return formattedDate;
        }
        // Generate issues
        const issues = Array.from({
            length: Math.ceil((endIssue - startIssue + 1) / 3) // Quantity of issues
        }).map((_, index) => {
            const releaseDate = new Date(startDate.getTime() + index * step); // Calculate release date
            const firstIssue = startIssue + index * 3; // First issue in the period
            const magazines = [firstIssue, firstIssue + 1, firstIssue + 2]
                .filter(num => num <= endIssue); // Last issue in the period
            return { magazines, releaseDate };
        });

        // Find release date
        return formatDate(issues.find(({ magazines }) => magazines.includes(number)).releaseDate);

    } else return null
}
